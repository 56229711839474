export enum SupportedLocale {
  EN = 'en',
  KO = 'ko',
  JA = 'ja',
  ES = 'es',
}

export enum localeNumbersSeparators {
  'en' = ',',
  'ja' = ',',
  'ko' = ',',
  'es' = '.',
}

export const DEFAULT_LOCALE = SupportedLocale.EN

export const SUPPORTED_LOCALES = Object.values(SupportedLocale)

export function isSupportedLocale(locale: string): locale is SupportedLocale {
  return SUPPORTED_LOCALES.includes(locale as SupportedLocale)
}
