import type { Location } from '@remix-run/react'
import { generatePath, useLocation } from '@remix-run/react'
import type { i18n as I18n } from 'i18next'
import { useTranslation } from 'react-i18next'

export function generatePathnameLocale(
  location: Location,
  changeLanguage: I18n['changeLanguage']
) {
  return (newLocale: string) => {
    const pathnameWithoutLocale = location.pathname
      .split('/')
      .slice(2)
      .join('/')

    changeLanguage(newLocale)
    return generatePath(['/:locale', pathnameWithoutLocale].join('/'), {
      locale: newLocale,
    })
  }
}

export function useChangePathnameLocale() {
  const location = useLocation()

  const { i18n } = useTranslation()

  return generatePathnameLocale(location, i18n.changeLanguage)
}
