export { useChangePathnameLocale } from './use-change-pathname-locale'
export { routeWithLocale } from './route-with-locale'
export { useLocale } from './use-locale'
export {
  SUPPORTED_LOCALES,
  DEFAULT_LOCALE,
  SupportedLocale,
} from './supported-locales'
export { Namespace } from './namespaces'
export { default as i18next } from './i18next.server'
export * from './shared-i18n-config'
export * from './detect-locale'
