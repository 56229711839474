import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from './supported-locales'
import { NAMESPACES, DEFAULT_NAMESPACE } from './namespaces'
import machineTranslationsProcessor from './machine-translations-processor'

export const i18nSharedConfig = {
  supportedLngs: SUPPORTED_LOCALES,
  fallbackLng: DEFAULT_LOCALE,
  ns: NAMESPACES,
  defaultNS: DEFAULT_NAMESPACE,
  react: { useSuspense: false },
  postProcess: machineTranslationsProcessor.name,
  interpolation: {
    escapeValue: false, // react already saves from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
}
