import { generatePath } from '@remix-run/react'

export function routeWithLocale(locale: string) {
  return (routePath: string) => {
    const pathWithoutLocale = routePath.split('/').slice(1).join('/')

    return generatePath(['/:locale', pathWithoutLocale].join('/'), {
      locale,
    })
  }
}
